<script>
import { authMethods } from "@/state/helpers";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { RESET_PASSWORD } from "@/state/actions.type";

export default {
  data() {
    return {
        pswdData: {newPassword: "", confirmPassword: ""},
        submitted: false,
        error: null,
        tryingToReset: false,
        isResetError: false,
        isResetSuccess: false,
        resetToken: ""
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    pswdData: {
        newPassword: {
          required,
          minLength: minLength(8),
          containsUppercase: function(value) {
            return /[A-Z]/.test(value)
          },
          containsLowercase: function(value) {
            return /[a-z]/.test(value)
          },
          containsNumber: function(value) {
            return /[0-9]/.test(value)
          },
          containsSpecial: function(value) {
            return /[#?!@$%^&*-]/.test(value)
          }
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('newPassword')
        },
    },
  },
  created() {
    this.resetToken = this.$route.query.resetToken;
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if(this.pswdData.newPassword !== this.pswdData.confirmPassword) {
            this.error = "Both passwords must match."
        } else {
            this.tryingToReset = true;
            // Reset the authError if it existed.
            this.error = null;
            return (
              this.$store
                .dispatch(`auth/${RESET_PASSWORD}`, {
                  new_password: this.pswdData.newPassword,
                  resetToken: this.resetToken
                })
                // eslint-disable-next-line no-unused-vars
                .then(token => {
                  this.tryingToReset = false;
                  this.isResetSuccess = true;
                })
                .catch(error => {
                  this.tryingToReset = false;
                  this.error = error ? error : "";
                  this.isResetError = true;
                })
            );
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/portone.png" height="30" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Create New Password</h4>
                        <p class="text-muted">Set new password for your PortOne Account.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                        >{{ notification.message }}</b-alert>

                        <b-alert
                          v-model="isResetSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >{{ notification.message }}</b-alert>

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-1">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="newPassword">New password</label>
                            <input
                              v-model="pswdData.newPassword"
                              type="password"
                              class="form-control"
                              id="newPassword"
                              name="newPassword"
                              placeholder="Enter new password"
                              :class="{ 'is-invalid': submitted && $v.pswdData.newPassword.$error }"
                            />
                          </div>
                          <div
                              v-if="submitted && !$v.pswdData.newPassword.required"
                              class="error"
                              style="font-size: 80%;"
                            >
                              New Password is required.
                            </div>
                            <div class="mb-4">
                              <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.newPassword.minLength">Password must have at least {{$v.pswdData.newPassword.$params.minLength.min}} letters.</div>
                              <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.newPassword.containsUppercase">Password must have Uppercase letter.</div>
                              <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.newPassword.containsLowercase">Password must have Lowercase letter.</div>
                              <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.newPassword.containsNumber">Password must have Numeric letter.</div>
                              <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.newPassword.containsSpecial">Password must have Special Character.</div>
                            </div>

                          <div class="form-group auth-form-group-custom mb-1">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="confirmPassword">Confirm password</label>
                            <input
                              v-model="pswdData.confirmPassword"
                              type="password"
                              class="form-control"
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder="Enter confirm password"
                              :class="{ 'is-invalid': submitted && $v.pswdData.confirmPassword.$error }"
                            />
                          </div>

                          <div class="mb-4">
                            <span class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.confirmPassword.required">Confirm password is required.</span>
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.pswdData.confirmPassword.sameAsPassword">Both passwords must match.</div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Reset Password</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Log in</router-link>
                        </p>
                        <p>
							© <a href="https://portone.io/global/en" class="text-primary">PortOne SG Pte. Ltd</a>
                          <!-- Crafted with <i class="mdi mdi-heart text-danger"></i> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <!-- <div class="bg-overlay"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
